import { Component } from '@angular/core';
import { SwiperOptions } from 'swiper/types';
@Component({
  selector: 'app-parteners',
  templateUrl: './parteners.component.html',
  styleUrls: ['./parteners.component.scss'],
})
export class PartenersComponent {
  brands = [
    {
      src: 'assets/images/partners/databricks.png',
      alt: 'Data Bricks',
    },
    {
      src: 'assets/images/partners/druid.png',
      alt: 'Druid AI',
    },
    {
      src: 'assets/images/partners/uipath.png',
      alt: 'UiPath',
    },
    {
      src: 'assets/images/partners/kubeark.png',
      alt: 'Kube Ark',
    },
    {
      src: 'assets/images/partners/smartrpa.png',
      alt: 'Smart RPA',
    }
  ];

  breakpoints = {
    320: {
      slidesPerView: 2,
      loop: true,
      spaceBetween: 20,
    },
    576: {
      slidesPerView: 2,
      loop: true,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      loop: true,
      spaceBetween: 20,
    },
    992: {
      slidesPerView: 3,
      loop: true,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 6,
      loop: false,
      spaceBetween: 30,
    },
    1400: {
      slidesPerView: 6,
      loop: false,
      spaceBetween: 30,
    },
  };

  swiperConfig: SwiperOptions = {
    updateOnWindowResize: true,
    breakpoints: this.breakpoints,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      stopOnLastSlide: false,
      pauseOnMouseEnter: true,
    },
  };
}
