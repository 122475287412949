import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SeoService } from './services/seo.service';
import { CookieConfigService } from './services/cookie-consent.service';
import { isPlatformBrowser } from '@angular/common';
import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routeAnimations', [
      transition('* <=> *', [
        query(
          ':enter, :leave',
          [
            style({
              position: 'absolute',
              width: '100%',
              opacity: 0,
              transform: 'translateX(100%)',
            }),
          ],
          { optional: true }
        ),
        group([
          query(
            ':leave',
            [
              animate(
                '500ms ease-in-out',
                style({ opacity: 0, transform: 'translateX(-50%)' })
              ),
            ],
            { optional: true }
          ),
          query(
            ':enter',
            [
              style({ transform: 'translateX(50%)' }),
              animate(
                '500ms ease-in-out',
                style({ opacity: 1, transform: 'translateX(0)' })
              ),
            ],
            { optional: true }
          ),
        ]),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'siel.ai';
  showFooter: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    private cookieConfigService: CookieConfigService,
    private seoService: SeoService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.googleAnalyticsService.getGoogleAnalyticsTrackId();

    this.router.events.subscribe((data: Event) => {
      if (data instanceof NavigationStart) {
        this.cdr.detectChanges();
      }
      if (data instanceof NavigationEnd) {
        let child = this.route.firstChild;
        if (child) {
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child?.snapshot.data) {
            this.seoService.updateSeoFromRoute(child.snapshot.data);
          }
        }
        if (isPlatformBrowser(this.platformId)) {
          gtag('event', 'page_view', { page_path: data.urlAfterRedirects });
        }
      }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieConfigService.initCookieConfig();
    }
  }

  onHeaderHeightChange(height: number) {
    const headerHeight = height;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  onAnimationDone(event: any) {
    this.showFooter = true;
  }

  onAnimationStart(event: any) {
    this.showFooter = false;
  }
}
