import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TestimonialService {
  baseURL = `${environment.apiUrl}/testimonial`;

  constructor(private http: HttpClient) {}

  getTestimonials(): Observable<any> {
    return this.http.get<any>(`${this.baseURL}`);
  }

  getTestimonial(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/${id}`);
  }
}
