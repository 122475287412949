import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { text } from '@fortawesome/fontawesome-svg-core';



@Component({
  selector: 'automation-as-a-service.component',
  templateUrl: './automation-as-a-service.component.html',
  styleUrls: ['./automation-as-a-service.component.scss'],
})
export class AutomationAsAServiceComponent {
  @ViewChild('section') section!: ElementRef;
  headerHeight: number = 0;
  chunkSize = 3;


  constructor(private headerService: HeaderService) {}

  AutomationAsAServiceServices = [
    {
      title: "AI Readiness Assessment",
      abstract: "We begin by evaluating your organization's current state in terms of data, technology, and processes. This comprehensive analysis helps us determine whether your business is ready for AI integration and identifies the gaps that need to be addressed before moving forward."
    },
    {
      title: "Discovery Workshops",
      abstract: "Through collaborative workshops with your stakeholders, we identify key areas where AI can have the most significant impact. These sessions help us align AI strategies with your business’s unique needs and goals, ensuring the AI implementation is focused on delivering the highest value."
    },
    {
      title: "Customized AI Strategy Development",
      abstract: "Next, we create a bespoke AI strategy tailored to your company’s objectives. This strategy identifies both quick-win opportunities and long-term goals, defining the specific AI technologies that will deliver the most value to your organization."
    },
    {
      title: "AI Implementation Roadmap",
      abstract: "We develop a detailed roadmap that outlines short-term and long-term milestones for your AI journey. The roadmap offers a clear, step-by-step guide from planning to execution, including pilot projects and full-scale deployment, ensuring that your AI initiatives are executed efficiently and effectively."
    },
    {
      title: "Pilot Projects",
      abstract: "We believe in starting small but impactful. We implement AI solutions incrementally, beginning with high-impact pilot projects that validate performance. This allows us to refine the solution and ensure alignment with your business processes before scaling the solution company-wide."
    },
    {
      title: "Training & Support",
      abstract: "Our team provides extensive training to your staff, ensuring that they are well-equipped to work with AI technologies. We offer ongoing support to ensure a smooth transition and maximum effectiveness of your AI initiatives."
    },
    {
      title: "Continuous Monitoring & Optimization",
      abstract: "AI systems need to adapt over time. We continuously monitor and optimize your AI systems to ensure they evolve with your business needs and continue delivering value, while maximizing ROI."
    },
    {
      title: "Comprehensive Implementations & Managed Services",
      abstract: "In addition to advisory work, SIEL AI offers full end-to-end implementations and managed services. We take your project from strategy to deployment, ensuring seamless execution and ongoing management to keep your AI solutions running at peak performance."
    }
  ];


  processesList = [
    {
      title: 'RevOps Automation',
    },
    {
      title: 'FinOps Automation',
    },
    {
      title: 'M&A Acceleration',
    },
    {
      title: 'Data & Systems Integration',
    },
    {
      title: 'Helpdesk Automation',
    }
  ];

  processes = [
    {
      number: '01',
      text: 'Subscribe to SIEL AI’s Automation as a Service (AaaS) platform for immediate access to pre-built automation solutions tailored to your industry.'
    },
    {
      number: '02',
      text: 'We deploy the solution rapidly, integrating it with your existing systems using OOTB connectors compatible with most CRMs, databases, and enterprise tools.'
    },
    {
      number: '03',
      text: 'Gain instant access to OOTB dashboards and reporting tools, enabling you to visualize key metrics and performance from day one.'
    },
    {
      number: '04',
      text: 'Automate critical processes across departments, with configurations made simple by toggling on or off specific functions as per your business needs.'
    },
    {
      number: '05',
      text: 'Our team handles all operational setup, ensuring seamless embedding of automation into your core operations without disrupting your workflow.'
    },
    {
      number: '06',
      text: 'Continuous optimization is powered by real-time monitoring and performance data, allowing for ongoing improvements tailored to your evolving needs.'
    },
    {
      number: '07',
      text: 'Achieve long-term resilience and agility as we handle troubleshooting, monitoring, and proactive updates, ensuring that your systems operate at peak efficiency.'
    }
  ];
  
  ngOnInit() {
    this.headerService.height$.subscribe((height) => {
      this.headerHeight = height;
      this.updateSectionHeight();
    });
    this.updateChunkSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateChunkSize();
  }

  updateChunkSize(): void {
    const width = window.innerWidth;

    if (width >= 1200) {
      this.chunkSize = 3;
    } else if (width >= 992) {
      this.chunkSize = 3;
    } else if (width >= 768) {
      this.chunkSize = 2;
    } else if (width >= 576) {
      this.chunkSize = 2;
    } else {
      this.chunkSize = 1;
    }
  }

  updateSectionHeight() {
    if (this.section) {
      this.section.nativeElement.style.marginTop = `${this.headerHeight}px`;
    }
  }

  ngAfterViewInit() {
    this.updateSectionHeight();
  }

  getChunks(list: any[], size: number): any[][] {
    const chunks = [];
    for (let i = 0; i < list.length; i += size) {
      chunks.push(list.slice(i, i + size));
    }
    return chunks;
  }

  get processesChunks() {
    return this.getChunks(this.processesList, this.chunkSize);
  }
}
