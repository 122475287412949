import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-people-strategy',
  templateUrl: './people-strategy.component.html',
  styleUrls: ['./people-strategy.component.scss']
})
export class PeopleStrategyComponent {
  @ViewChild('section') section!: ElementRef;
  headerHeight: number = 0;
  chunkSize = 3;


  constructor(private headerService: HeaderService) {}


  
  

  peopleStrategyList = [
    {
      title: 'Enhanced Workforce Adaptability',
    },
    {
      title: 'Improved Employee Retention',
    },
    {
      title: 'Increased Productivity through Automation',
    },
    {
      title: 'Efficient Talent Acquisition and Retention',
    },
    {
      title: 'Upskilled Workforce for Future Technologies',
    },
    {
      title: 'Stronger Leadership in a Tech-Driven Environment',
    }
    
  ];


  ngOnInit() {
    this.headerService.height$.subscribe((height) => {
      this.headerHeight = height;
      this.updateSectionHeight();
    });
    this.updateChunkSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateChunkSize();
  };

  updateChunkSize(): void {
    const width = window.innerWidth;

    if (width >= 1200) {
      this.chunkSize = 3;
    } else if (width >= 992) {
      this.chunkSize = 3;
    } else if (width >= 768) {
      this.chunkSize = 2;
    } else if (width >= 576) {
      this.chunkSize = 2;
    } else {
      this.chunkSize = 1;
    }
  }

  updateSectionHeight() {
    if (this.section) {
      this.section.nativeElement.style.marginTop = `${this.headerHeight}px`;
    }
  }

  ngAfterViewInit() {
    this.updateSectionHeight();
  }

  getChunks(list: any[], size: number): any[][] {
    const chunks = [];
    for (let i = 0; i < list.length; i += size) {
      chunks.push(list.slice(i, i + size));
    }
    return chunks;
  }

  get processesChunks() {
    return this.getChunks(this.peopleStrategyList, this.chunkSize);
  }

}
