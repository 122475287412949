import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { text } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-process-optimization',
  templateUrl: './process-optimization.component.html',
  styleUrls: ['./process-optimization.component.scss'],
})
export class ProcessOptimizationComponent {
  @ViewChild('section') section!: ElementRef;
  headerHeight: number = 0;
  chunkSize = 3;


  constructor(private headerService: HeaderService) {}

  processOptimizationServices = [
    {
      title: "AI Readiness Assessment",
      abstract: "We begin by evaluating your organization's current state in terms of data, technology, and processes. This comprehensive analysis helps us determine whether your business is ready for AI integration and identifies the gaps that need to be addressed before moving forward."
    },
    {
      title: "Discovery Workshops",
      abstract: "Through collaborative workshops with your stakeholders, we identify key areas where AI can have the most significant impact. These sessions help us align AI strategies with your business’s unique needs and goals, ensuring the AI implementation is focused on delivering the highest value."
    },
    {
      title: "Customized AI Strategy Development",
      abstract: "Next, we create a bespoke AI strategy tailored to your company’s objectives. This strategy identifies both quick-win opportunities and long-term goals, defining the specific AI technologies that will deliver the most value to your organization."
    },
    {
      title: "AI Implementation Roadmap",
      abstract: "We develop a detailed roadmap that outlines short-term and long-term milestones for your AI journey. The roadmap offers a clear, step-by-step guide from planning to execution, including pilot projects and full-scale deployment, ensuring that your AI initiatives are executed efficiently and effectively."
    },
    {
      title: "Pilot Projects",
      abstract: "We believe in starting small but impactful. We implement AI solutions incrementally, beginning with high-impact pilot projects that validate performance. This allows us to refine the solution and ensure alignment with your business processes before scaling the solution company-wide."
    },
    {
      title: "Training & Support",
      abstract: "Our team provides extensive training to your staff, ensuring that they are well-equipped to work with AI technologies. We offer ongoing support to ensure a smooth transition and maximum effectiveness of your AI initiatives."
    },
    {
      title: "Continuous Monitoring & Optimization",
      abstract: "AI systems need to adapt over time. We continuously monitor and optimize your AI systems to ensure they evolve with your business needs and continue delivering value, while maximizing ROI."
    },
    {
      title: "Comprehensive Implementations & Managed Services",
      abstract: "In addition to advisory work, SIEL AI offers full end-to-end implementations and managed services. We take your project from strategy to deployment, ensuring seamless execution and ongoing management to keep your AI solutions running at peak performance."
    }
  ];


  processesList = [
    {
      title: 'Invoice Processing',
    },
    {
      title: 'Customer Service Automation',
    },
    {
      title: 'Employee Onboarding',
    },
    {
      title: 'Data Entry and Management',
    },
    {
      title: 'Payroll Processing',
    },
    {
      title: 'Inventory Management',
    },
    {
      title: 'Order Processing',
    },
    {
      title: 'IT Support and Ticketing',
    },
  ];

  processes = [
      {
        number: '01',
        text: 'Recognize the need for change and the impact of disruption on your industry.'
      },
      {
        number: '02',
        text: 'Explore potential solutions, technologies, and business models that can drive transformation.'
      },
      {
        number: '03',
        text: 'Test ideas through small-scale experiments and pilot initiatives.'
      },
      {
        number: '04',
        text: 'Reengineer business processes and integrate new solutions at scale.'
      },
      {
        number: '05',
        text: 'Embed the new systems into the organization’s core operations.'
      },
      {
        number: '06',
        text: 'Optimize and refine processes through continuous improvement based on data.'
      },
      {
        number: '07',
        text: 'Achieve sustainability and resilience by building a culture of agility and innovation.'
      }
        
  ];

  ngOnInit() {
    this.headerService.height$.subscribe((height) => {
      this.headerHeight = height;
      this.updateSectionHeight();
    });
    this.updateChunkSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateChunkSize();
  }

  updateChunkSize(): void {
    const width = window.innerWidth;

    if (width >= 1200) {
      this.chunkSize = 3;
    } else if (width >= 992) {
      this.chunkSize = 3;
    } else if (width >= 768) {
      this.chunkSize = 2;
    } else if (width >= 576) {
      this.chunkSize = 2;
    } else {
      this.chunkSize = 1;
    }
  }

  updateSectionHeight() {
    if (this.section) {
      this.section.nativeElement.style.marginTop = `${this.headerHeight}px`;
    }
  }

  ngAfterViewInit() {
    this.updateSectionHeight();
  }

  getChunks(list: any[], size: number): any[][] {
    const chunks = [];
    for (let i = 0; i < list.length; i += size) {
      chunks.push(list.slice(i, i + size));
    }
    return chunks;
  }

  get processesChunks() {
    return this.getChunks(this.processesList, this.chunkSize);
  }
}
