import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot } from '@angular/router';
import { BlogService } from '../services/newsletter.service';

export const BlogPostResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot
) => {
  const id = route.paramMap.get('id') as string;
  return inject(BlogService).getBlog(id);
};
