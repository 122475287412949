import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriberService {
  baseURL = `${environment.apiUrl}/subscriber`;

  constructor(private http: HttpClient) {}

  subscribeNewsletter(subscriberInfo: any): Observable<any> {
    return this.http.post<any>(`${this.baseURL}/subscribe`, subscriberInfo);
  }
}
