import { Component, ElementRef, ViewChild } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../services/newsletter.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss'],
})
export class BlogPostComponent {
  @ViewChild('section') section!: ElementRef;
  blog: any = [];
  mail: string = '';
  relatedBlogs: any = [];
  sendForm: FormGroup;
  headerHeight: number = 0;
  apiError: string | null = null;
  isSent: boolean = false;

  constructor(
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private blogService: BlogService,
    private fb: FormBuilder
  ) {
    this.sendForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    this.headerService.height$.subscribe((height) => {
      this.headerHeight = height;
      this.updateSectionHeight();
    });
    this.route.data.subscribe((data) => {
      this.blog = data['blog'];

      this.blogService.getRelatedBlogs(this.blog.id).subscribe((data) => {
        this.relatedBlogs = data;
      });
    });
  }

  updateSectionHeight() {
    if (this.section) {
      this.section.nativeElement.style.marginTop = `${this.headerHeight}px`;
    }
  }

  ngAfterViewInit() {
    this.updateSectionHeight();
  }

  sendBlogPdf(id: string) {
    if (this.sendForm.valid) {
      this.blogService
        .sendBlogPdf(id, this.sendForm.value.email)
        .subscribe((response) => {
          console.log(response);
          if (response.message === 'OK') {
            this.isSent = true;
          } else {
            this.apiError = 'An error occurred while sending the email';
          }
        });
    } else {
      this.apiError = 'Please enter a valid email address';
    }
  }
}
