import { Injectable } from '@angular/core';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentService,
} from 'ngx-cookieconsent';

@Injectable({
  providedIn: 'root',
})
export class CookieConfigService {
  public static getDefaultCookieConstentConfig(): NgcCookieConsentConfig {
    return {
      cookie: {
        domain: 'localhost',
        secure: false,
      },
      palette: {
        popup: {
          background: '#6a00ff',
        },
        button: {
          background: '#A8F97D',
          text: '#000000',
        },
      },
      theme: 'classic',
      type: 'opt-out',
      layout: 'custom',
      layouts: {
        custom: '{{messagelink}}{{allowButton}}',
      },
      position: 'bottom',
      revokeBtn: `<div style="display: none"></div>`,
      elements: {
        messagelink: `
        <span id="cookieconsent:desc" class="cc-message">{{message}}
          <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> and our
          <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>.
        </span>
      `,
        allowButton: `
        <span aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow">{{allow}}</span>
      `,
        closeButton: `
        '<span aria-label="dismiss cookie message" tabindex="0" class="cc-close">{{close}}</span>'
      `,
      },
      content: {
        message:
          'By using our site, you acknowledge that you have read and understand our ',
        privacyPolicyLink: 'Privacy Policy',
        privacyPolicyHref: `/privacy-policy`,
        tosLink: 'Terms of Service',
        tosHref: `/terms-and-conditions`,
      },
    };
  }

  constructor(private cookieConsentService: NgcCookieConsentService) {}

  initCookieConfig(): void {
    const config = this.cookieConsentService.getConfig();
    config.position = 'bottom';

    this.cookieConsentService.destroy();
    this.cookieConsentService.init(config);
  }
}
