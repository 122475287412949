import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { HeaderService } from '../services/header.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../services/contact.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, AfterViewInit {
  @ViewChild('section') section!: ElementRef;
  headerHeight: number = 0;
  contactForm: FormGroup;
  submitted = false;
  contactSended: boolean = false;
  apiError: string | null = null;
  options = [
    { value: 'ai-assessment', label: 'Request an AI Assessment' },
    {
      value: 'explore-solutions',
      label:
        'Explore Agentic AI Solutions. Choose this option to receive a detailed evaluation of your AI needs and capabilities.',
    },
    {
      value: 'streamline-processes',
      label:
        'Streamline High-Value Processes. Select this to learn more about how agentic AI can revolutionize your business operations.',
    },
    {
      value: 'custom-consultation',
      label:
        'Custom AI Consultation. Opt for this to discover how AI can optimize and automate your most critical business processes.',
    },
    {
      value: 'personalized-consultation',
      label:
        'Pick this to request a personalized consultation for tailored AI solutions that meet your specific needs.',
    },
  ];

  constructor(
    private headerService: HeaderService,
    private fb: FormBuilder,
    private contactService: ContactService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactForm = this.fb.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.pattern('^[0-9]*$')],
      details: ['', Validators.required],
      options: [[], Validators.required],
      agreement: [false, Validators.requiredTrue],
      newsletter: [false],
    });
  }

  ngOnInit() {
    this.headerService.height$.subscribe((height) => {
      this.headerHeight = height;
      this.updateSectionHeight();
    });
  }

  updateSectionHeight() {
    if (this.section) {
      this.section.nativeElement.style.marginTop = `${this.headerHeight}px`;
      this.section.nativeElement.style.minHeight = `calc(100vh - ${this.headerHeight}px)`;
    }
  }

  ngAfterViewInit() {
    this.updateSectionHeight();
  }

  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    this.recaptchaV3Service
      .execute('submitContact')
      .subscribe((token: string) => {
        this.contactService
          .contact({ ...this.contactForm.value, recaptcha: token })
          .subscribe({
            next: (response) => {
              if (response) {
                this.contactSended = true;
                this.contactForm.reset();
                Object.keys(this.contactForm.controls).forEach((key) => {
                  this.contactForm.get(key)?.setErrors(null);
                });
              }
            },
            error: (error) => {
              this.apiError = error.error.message;
            },
          });
      });
  }
}
