import { Component, ElementRef, ViewChild } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent {
  @ViewChild('teamSwiper') swiper!: ElementRef<SwiperContainer>;

  teamMembers = [
    {
      name: 'Alexandru Bene',
      position: 'Founder & CEO',
      image: 'assets/images/team/bene.jpg',
    },
    {
      name: 'Anubhai Mehta',
      position: 'Chief Strategy Officer',
      image: 'assets/images/team/anubhai.jpg',
    },
    {
      name: 'Ruxandra Croitoru',
      position: 'Co-Founder and Customer Success Advisor',
      image: 'assets/images/team/ruxandra.jpeg',
    },
    // {
    //   name: 'Kris Payne',
    //   position: 'Advisor',
    //   image: 'assets/images/team/krys.png',
    // },
    {
      name: 'Subash Nataranjan',
      position: 'Field CTO',
      image: 'assets/images/team/subash.jpg',
    },
  ];

  breakpoints = {
    320: {
      spaceBetween: 20,
      slidesPerView: 1.2,
    },
    576: {
      spaceBetween: 20,
      slidesPerView: 2.2,
    },
    768: {
      spaceBetween: 20,
      slidesPerView: 2.2,
    },
    992: {
      spaceBetween: 30,
      slidesPerView: 3.2,
    },
    1200: {
      spaceBetween: 30,
      slidesPerView: 4.2,
    },
  };

  swiperConfig: SwiperOptions = {
    updateOnWindowResize: true,
    breakpoints: this.breakpoints,
    direction: 'horizontal',
    loop: true,
    effect: 'slide',
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  };
}
