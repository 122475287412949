<div class="container-fluid p-0">
  <section class="contact-section pt-3 pb-4 py-md-5">
    <div class="contact-container mt-5 py-3 py-md-4 px-2 px-md-4 fade">
      <h2 class="contact-title mb-3">Contact Us</h2>
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="mb-2">
          <label for="name" class="form-label contact-label">Full Name</label>
          <input id="name" formControlName="fullName" type="text" class="form-control custom-input"  [ngClass]="{'is-invalid': contactForm.get('fullName')?.invalid && (contactForm.get('fullName')?.touched || contactForm.get('fullName')?.dirty)}"/>
          <div *ngIf="contactForm.get('fullName')?.invalid && (contactForm.get('fullName')?.touched || contactForm.get('fullName')?.dirty)" class="invalid-feedback">
            Full Name is required.
          </div>
        </div>
        <div class="mb-2">
          <label for="email" class="form-label contact-label">Email Address</label>
          <input id="email" formControlName="email" type="email" class="form-control custom-input"  [ngClass]="{'is-invalid': contactForm.get('email')?.invalid && (contactForm.get('email')?.touched || contactForm.get('email')?.dirty)}"/>
          <div *ngIf="contactForm.get('email')?.invalid && (contactForm.get('email')?.touched || contactForm.get('email')?.dirty)" class="invalid-feedback">
            Email address is required.
          </div>
          <div *ngIf="contactForm.get('email')?.errors?.['email'] && (contactForm.get('email')?.touched || contactForm.get('email')?.dirty)" class="invalid-feedback">
            Invalid email address format.
          </div>
        </div>
        <div class="mb-2">
          <label for="phone" class="form-label contact-label">Phone Number</label>
          <input id="phone" formControlName="phone" type="phone" class="form-control custom-input">
          <div *ngIf="contactForm.get('phone')?.invalid && (contactForm.get('phone')?.touched || contactForm.get('phone')?.dirty)" class="invalid-feedback">
            Invalid phone number format.
          </div>
        </div>
        <div class="mb-2">
          <label for="details" class="form-label contact-label">Message</label>
          <textarea id="details" [rows]="5" formControlName="details" class="form-control custom-input" [ngClass]="{'is-invalid': contactForm.get('details')?.invalid && (contactForm.get('details')?.touched || contactForm.get('details')?.dirty)}"></textarea>
          <div *ngIf="contactForm.get('details')?.invalid && (contactForm.get('details')?.touched || contactForm.get('details')?.dirty)" class="invalid-feedback">
            Message is required.
          </div>
        </div>
        <div class="mb-2">
          <label for="options" class="form-label contact-label">Options</label>
          <select id="options" formControlName="options" class="form-control custom-select">
            <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="form-check custom-checkbox agreement-checkbox mt-3">
          <input class="form-check-input" required type="checkbox" value="" id="agreement" formControlName="agreement"  [ngClass]="{'is-invalid': contactForm.get('agreement')?.invalid && (contactForm.get('agreement')?.touched || contactForm.get('agreement')?.dirty)}" />
          <label class="form-check-label">
            I agree to the <a target="_blank" [routerLink]="['/terms-and-conditions']">terms and conditions</a>.
          </label>
        </div>
        <div *ngIf="contactForm.get('agreement')?.invalid && (contactForm.get('agreement')?.touched || contactForm.get('agreement')?.dirty)" class="invalid-feedback d-block">
          You must agree to the terms and conditions.
        </div>
        <div class="form-check custom-checkbox agreement-checkbox mt-1">
          <input class="form-check-input" type="checkbox" value="" id="newsletter" formControlName="newsletter"  [ngClass]="{'is-invalid': submitted && contactForm.get('newsletter')?.invalid}" />
          <label class="form-check-label">
            Subscribe to our newsletter.
          </label>
        </div>
        <button *ngIf="!submitted" [disabled]="!contactForm.valid" type="submit" class="mt-3">SUBMIT</button>
        <div class="text-center w-100 mt-3">
          <div *ngIf="submitted && !apiError">
            <p class="success-text">Thank you for contacting us. We will get back to you shortly.</p>
          </div>
          <div *ngIf="submitted && apiError" class="mt-3">
            <p class="error-text">{{apiError}}</p>
          </div>
          This site is protected by reCAPTCHA and the Google
          <a target="_blank" class="link" href="https://policies.google.com/privacy">Privacy Policy</a>
          and
          <a target="_blank" class="link" href="https://policies.google.com/terms">Terms of Service</a>
          apply.
        </div>
      </form>
    </div>
  </section>
</div>
