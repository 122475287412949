import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TestimonialService } from '../services/testimonial.service';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit {
  @ViewChild('testimonialSwiper') swiper!: ElementRef<SwiperContainer>;

  testimonials: any[] = [];

  constructor(private testimonialsService: TestimonialService) {}

  ngOnInit(): void {
    this.testimonialsService.getTestimonials().subscribe((data: any[]) => {
      this.testimonials = data;
    });
  }

  swiperConfig: SwiperOptions = {
    updateOnWindowResize: true,
    direction: 'horizontal',
    slidesPerView: 1,
    loop: true,
    effect: 'slide',
    autoplay: {
      delay: 15000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  };
}
