<div class="container">
  <section #section class="py-3 fade-down-up">
    <h1 class="section-title pb-4 mb-4">Who</h1>
    <div class="card d-flex flex-column justify-content-center px-0 px-md-2 px-lg-4">
      <div class="d-flex flex-column w-100 px-2 px-lg-3 mb-3">
        <div class="d-flex flex-column-reverse flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-6 mt-3 mt-lg-0 justify-content-end">
            <h2 class="section-title-2 team-name">Alex <br/> Bene</h2>
            <h4 class="section-subtitle pt-2 pb-2 pb-lg-0">Founder CEO</h4>
          </div>
          <div class="d-flex flex-column col-12 col-lg-6 justify-content-end team-img">
              <div
                [style.width]="'100%'"
                [style.height]="'100%'"
                [style.background-image]="'url(assets/images/team-landscape/bene.png)'"
                [style.backgroundSize]="'cover'"
                [style.backgroundPosition]="'top center'"
                [style.borderRadius]="'50px'">
              </div>
              <div class="team-icon">-</div>
          </div>
        </div>
        <div class="d-flex flex-column-reverse flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-6 pe-lg-4">
            <p class="description py-2 py-lg-3">Since graduating more than 10 years ago, Alex has spent his time becoming a true professional with a passion for leveraging cutting-edge technologies to drive efficiency and innovation within organizations. He has a strong background in automation solutions and a track record of delivering transformative results, thriving on solving complex business challenges through intelligent automation strategies.</p>
          </div>
          <div class="d-flex flex-column col-12 col-lg-6">
            <p class="description py-2 py-lg-3">Alex is a coffee nerd, spending his mornings perfecting his latte art, he would at one point like to own a sailboat and slowly but surely move and live in it, and has a newly discovered passion for watches.</p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column w-100 mt-lg-3 px-2 px-lg-3">
        <div class="d-flex flex-column flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-55 justify-content-end team-img"
              [style.background-image]="'url(assets/images/team-landscape/anubhai.png)'"
              [style.backgroundSize]="'cover'"
              [style.backgroundPosition]="'top center'">
          </div>
          <div class="d-flex flex-column col-12 col-lg-45 mt-3 mt-lg-0 justify-content-end">
            <div class="ps-lg-3">
              <h2 class="section-title-2 team-name">Anubhai <br/> Mehta</h2>
              <h4 class="section-subtitle pt-2 pb-2 pb-lg-0">Chief Strategy Officer</h4>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-45 pe-lg-3">
            <p class="description py-2 py-lg-3">Anubhai (Anu) Mehta is an experienced leader with expertise in growing global technology businesses and leading sales teams for Global System Integrators (GSIs), transforming Small & Medium Businesses (SMBs), and scaling Startups for successful exits.</p>
          </div>
          <div class="d-flex flex-column col-12 col-lg-55">
            <p class="description py-2 py-lg-3">As an Advisor, he is passionate about helping organizations reinvent in order to achieve their business goals, by leveraging the power of their people together with the transformative power of technology.
              <br/>
              <br/>
              He also has extensive expertise in the design of sales teams and global delivery organizations with diversified portfolios - Strategy, PMO, Cybersecurity, Intelligent Automation, Enterprise Architecture, and Hybrid Cloud Platforms. Anu lives near Princeton, NJ and is blessed with a beautiful and loving family. He enjoys traveling, martial arts, sushi, and walks in the park with the youngest family member.</p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column w-100 mt-3 mt-lg-5 px-2 px-lg-3 mb-3">
        <div class="d-flex flex-column-reverse flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-6 mt-3 mt-lg-0 justify-content-end">
            <h2 class="section-title-2 team-name">Ruxandra <br/> Croitoru</h2>
            <h4 class="section-subtitle pt-2 pb-2 pb-lg-0">Co-Founder & <br/> Customer Success Advisor</h4>
          </div>
          <div class="d-flex flex-column col-12 col-lg-6 justify-content-end team-img">
              <div
                [style.width]="'100%'"
                [style.height]="'100%'"
                [style.background-image]="'url(assets/images/team-landscape/ruxandra.png)'"
                [style.backgroundSize]="'cover'"
                [style.backgroundPosition]="'top center'"
                [style.borderRadius]="'50px'">
              </div>
              <div class="team-icon-2">-</div>
          </div>
        </div>
        <div class="d-flex flex-column-reverse flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-6 pe-lg-4">
            <p class="description py-2 py-lg-3">Ruxandra Croitoru is a digital transformation strategist who's making waves in the tech industry. With a keen eye for innovation and a talent for driving organizational growth, she's become a leading voice in hyper-automation, business process optimization and AI. Her strategic leadership resulted in groundbreaking projects for a variety of organizations at a global level.</p>
          </div>
          <div class="d-flex flex-column col-12 col-lg-6">
            <p class="description py-2 py-lg-3">Ruxandra's track record of success ranges from building successful business relationships and strategic collaborations, to thought leadership and flawless project delivery, while also pioneering growth initiatives and trends. Highly creative, her passion is to empower people by blending clarity and simplicity with complex technologies.
              <br/>
              <br/>
              Ruxandra lives in Bucharest, Romania and loves exploring natural settings with her son, winter sports, and often volunteers for causes close to her heart.</p>
          </div>
        </div>
      </div>
      <div class="svg-team-container">
        <img src="assets/images/team/team-1.svg" alt="Decorative SVG Team 1">
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center px-0 px-md-2 px-lg-4">
      <!-- <div class="d-flex flex-column w-100 px-2 px-lg-3">
        <div class="d-flex flex-column flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-55 justify-content-end team-img"
              [style.background-image]="'url(assets/images/team-landscape/bene.png)'"
              [style.backgroundSize]="'cover'"
              [style.backgroundPosition]="'top center'">
          </div>
          <div class="d-flex flex-column col-12 col-lg-45 mt-3 mt-lg-0 justify-content-end">
            <div class="ps-lg-3">
              <h2 class="section-title-2 primary-color team-name">Kris <br/> Payne</h2>
              <h4 class="section-subtitle primary-color pt-2 pb-2 pb-lg-0">Advisor</h4>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-45 pe-lg-3">
            <p class="description-second py-2 py-lg-3">With a career spanning over 20 years, Kris has experience working in Information Technology with a focus on cloud and data services, IT assessments, systems architecture, management and strategic alignment. Kris has a proven track record of successfully analyzing support requirements, identifying areas for improvement and opportunities, and then developing innovative solutions to increase reliability and improve productivity.</p>
          </div>
          <div class="d-flex flex-column col-12 col-lg-55">
            <p class="description-second py-2 py-lg-3">Kris has held leadership positions as a Vice President of Infrastructure with IHS Markit and the global lead of cloud native platforms at NTT Data Services. He has been providing top tier services across multiple product and support lines. Prior to IHS he spent several years leading a System Engineering and Architecture team at a telecommunications service provider. Kris has managed both on-premise and remote teams across the globe. His strengths are strategy, consulting and solution architecture and his weakness is his lionhead rabbit named The Bunnisher.</p>
          </div>
        </div>
      </div> -->
      <div class="d-flex flex-column w-100 mt-3 mt-lg-5 px-2 px-lg-3 mb-3">
        <div class="d-flex flex-column-reverse flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-6 mt-3 mt-lg-0 justify-content-end">
            <h2 class="section-title-2 primary-color team-name">Subash <br/> Nataranjan</h2>
            <h4 class="section-subtitle primary-color pt-2 pb-2 pb-lg-0">Field CTO</h4>
          </div>
          <div class="d-flex flex-column col-12 col-lg-6 justify-content-end team-img">
            <div
              [style.width]="'100%'"
              [style.height]="'100%'"
              [style.background-image]="'url(assets/images/team-landscape/subash.png)'"
              [style.backgroundSize]="'cover'"
              [style.backgroundPosition]="'top center'"
              [style.borderRadius]="'50px'">
            </div>
            <div class="team-icon">-</div>
          </div>
        </div>
        <div class="d-flex flex-column-reverse flex-lg-row card-content">
          <div class="d-flex flex-column col-12 col-lg-6 pe-lg-4">
            <p class="description-second py-2 py-lg-3">Subash, a seasoned IT professional with 15 years of experience, excels at aligning market trends and translating business objectives into impactful technology strategies. His comprehensive background spans systems and software engineering, consulting, and leadership roles.</p>
          </div>
          <div class="d-flex flex-column col-12 col-lg-6">
            <p class="description-second py-2 py-lg-3">As the author of “Multi-cloud Handbook for Developers” and an organizer of global tech community events, Subash is a passionate thought leader who empowers others through knowledge sharing and mentorship. He thrives on tackling complex business challenges and harnessing cutting-edge technologies to drive innovative solutions. Subash is a proud father, is passionate about human engineering and nutrition and has a farm that he automated himself.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
